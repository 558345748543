.admindestroy__bg {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -3;
  background: url('../images/bglogo.webp');
  height: 100%;
  width: 100%;
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.hostWaitingLobby {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: max-content;
  margin-top: 100px;
}
.end_settings > img {
  cursor: pointer;
}
.timer__round {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1300px;
  margin-bottom: 5px;
}
.endGame {
  margin-bottom: 50px;
  width: 200px;
  cursor: pointer;
}
.settings {
  cursor: pointer;
  margin-left: auto;
}
.admin__round {
  background: url('../images/timer.svg');
  height: 45px;
  width: 150px;
  color: white;
  font-size: 20px;
  font-weight: bolder;
  padding: 7px 0 6px 37px;
  margin-top: -30px;
}
/* background of round number */
.timer_round > div > p {
  background: url('../images/timer.svg');
  background-size: cover;
  width: 186px;
  height: 53px;
  display: grid;
  place-items: center;
  font-size: large;
  font-weight: 700;
  color: #ffffff;
}
.pauseTimer_nextRound > img {
  cursor: pointer;
  background-size: cover;
  width: 120px;
}
.mainWord,
.teamNameBlue,
.teamNameRed {
  background-size: cover !important;
  display: grid;
  place-items: center;
  width: 190px;
  height: 45px;
  width: fit-content;
  font-weight: 700;
  color: #ffffff;
  position: relative;
}
.teamNameBlue {
  background: url('../images/team_name_blue.svg');
  transform: translate(100px, -100px);
  padding-bottom: 5px;
}
.teamNameRed {
  background: url('../images/team_nam_red.svg');
  transform: translate(-100px, 100px);
  padding-bottom: 5px;
}
.mainWord {
  font-size: 20px !important;
  letter-spacing: 1.5px;
  width: 200px;
  height: 40px;
  padding-bottom: 10px;
  text-transform: uppercase;
}
.admin__similar {
  font-weight: bolder;
  text-shadow: 10px solid black;
}
.destroy {
  width: 150px;
}
.join {
  display: flex;
  align-items: center;
}
.teams {
  color: #ffffff;
  background: url('../images/centre_box.svg');
  background-size: cover !important;
  width: 750px;
  height: 400px;
  padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.allPlayers {
  width: 500px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.player {
  display: flex;
  justify-content: center;
}
.player > div {
  margin: 3px 0px 0px 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 5px solid #ffffff;
}
.player .answered {
  background-color: #1bd5e2;
  overflow: hidden;
}
.hostWaitingLobby {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: max-content;
  margin-top: 100px;
}
.end_settings,
.timer_round,
.pauseTimer_nextRound {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 900px;
  margin-bottom: 5px;
}
.endGame {
  width: 200px;
}
.timer_round > p {
  background: url('../images/timer.svg');
  background-size: cover;
  width: 186px;
  height: 53px;
  display: grid;
  place-items: center;
  font-size: large;
  font-weight: 700;
  color: #ffffff;
}
.pauseTimer_nextRound > img {
  background-size: cover;
  width: 120px;
}
.mainWord,
.teamNameBlue,
.teamNameRed {
  background-size: cover !important;
  display: grid;
  place-items: center;
  min-width: 190px;
  min-height: 45px;
  width: fit-content;
  font-weight: 700;
  color: #ffffff;
  position: relative;
}
.teamNameBlue {
  background: url('../images//team_name_blue.svg');
  transform: translate(100px, -100px);
}
.teamNameRed {
  background: url('../images//team_nam_red.svg');
  transform: translate(-100px, 100px);
}
.mainWord {
  font-size: medium;
  /* background: url('../images/mainword.svg'); */
  width: 200px;
  height: 50px;
  margin: 10px auto -40px auto;
}
.destroy {
  width: 150px;
}
.join {
  display: flex;
  align-items: center;
}
.teams {
  color: #ffffff;
  background: url('../images/centre_box.svg');
  background-size: cover !important;
  width: 750px;
  height: 400px;
  padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.allPlayers {
  width: 500px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.player {
  display: flex;
  justify-content: center;
}
.player > div {
  margin: 3px 0px 0px 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 5px solid #ffffff;
}
.player .answered {
  background-color: #1bd5e2;
}

.pointSend {
  cursor: pointer;
  position: relative;
  top: -45px;
  left: 420px;
}
.admin__destroy {
  cursor: pointer;
  background: #34c78f;
  color: white;
  height: 2.5rem;
  width: 5.5rem;
  font-weight: 900;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  border-radius: 19.9895px;
  border: none;
}

/* ========================== Destroy Admin ======================== */

.seperateBoard {
  color: #ffffff;
  background: url('../images/centre_box_with_partition.svg');
  /* z-index: -2; */
  background-size: cover !important;
  width: 750px;
  height: 400px;
  padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.destroyButton > img {
  cursor: pointer;
  width: 150px;
}

.team {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 550px;
  gap: 20px;
}

.eachTeam {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.word {
  /* z-index: 5; */
  font-weight: 800;
  padding: 0px 10px;
  background: url('../images/timer.svg');
  background-size: cover;
  height: 25px;
  min-width: 100px;
  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px;
  font-size: small;
}
.wprd input {
  z-index: 5;
}
.word > span:last-child {
  cursor: pointer;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ff0000;
  border: 3px solid #ffffff;
}
@media only screen and (max-width: 1610px), screen and (max-height: 910) {
  img.endgame {
    width: 150px;
  }
  .seperateBoard {
    transform: translateY(-110px);
  }
  .mainWord {
    transform: translateY(-100px);
    z-index: 3;
  }
  span.teamNameBlue {
    transform: translate(120px, -175px);
    z-index: 3;
  }
  span.teamNameRed {
    transform: translate(-100px, -25px);
  }
  .admin__destroy {
    transform: translateY(-100px);
  }
}
@media only screen and (max-width: 1280px), screen and (max-height: 720px) {
  .timer__round {
    width: 1000px;
  }
  .seperateBoard {
    transform: translateY(-70px);
  }
  .mainWord {
    transform: translateY(-60px);
    z-index: 3;
  }
  span.teamNameBlue {
    transform: translate(110px, -150px);
    z-index: 3;
  }
  span.teamNameRed {
    transform: translate(-100px, 25px);
  }
  .destroyButton > img {
    transform: translateY(20px);

    cursor: pointer;
    width: 150px;
  }
  .admin__destroy {
    transform: translateY(-75px);
  }
}
