.point__bg {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -3;
  background: url('../images/map3.svg');
  height: 100%;
  width: 100%;
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.point {
  font-family: 'PaytoneOne';
}
.point__controls {
  margin-top: 100px;
  width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

.point__controls > div:first-child {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.point__controls > div:last-child {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.point__teamscores {
  font-size: 20px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 500px;
  transform: translateY(40px);
}
.point__mainword {
  font-size: 24px !important;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.point__controls > div > div {
  margin: 10px 0px;
}
.point__endgame {
  width: 190px;
}
.point__board {
  position: absolute;
  left: 50%;
  margin-top: 50px;
  margin-left: -155px;
  top: 43%;
}

.point__board > h3,
.point__board > h6 {
  color: white;
}
.point__board button {
  margin: 0px 2px;
}
.plus_five,
.plus_four,
.plus_three,
.plus_zero {
  cursor: pointer;
  background: #34c78f;
  color: white;
  height: 2.5rem;
  width: 4.5rem;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  border-radius: 19.9895px;
  border: none;
}
.wrong {
  cursor: pointer;
  background: #d6312f;
  color: white;
  height: 2.5rem;
  padding: 0px 15px;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  border-radius: 19.9895px;
  border: none;
}
.point__setting {
  cursor: pointer;
}
.point__randomword {
  color: #34c78f;
}
.point__timer,
.point__round {
  background: url('../images/timer.svg');
  background-size: cover;
  width: 186px;
  height: 53px;
  display: grid;
  place-items: center;
  font-size: large;
  color: #ffffff;
}

.pauseTimer > img,
.nextRound > img {
  background-size: cover;
  width: 120px;
}

.point__timer {
  cursor: pointer;
  position: relative;
  display: grid;
  place-items: center;
}
.point__timer > h1 {
  position: absolute;
}
.point__round {
  cursor: pointer;
  position: relative;
  display: grid;
  place-items: center;
}
.point__round > h1 {
  position: absolute;
}
.point__send {
  cursor: pointer;
  transform: translate(200px, -43px);
}
@media only screen and (max-width: 1920px) {
  .point__teamscores {
    transform: translateY(140px);
  }
  .point__board {
    margin-top: 80px;
  }
}
@media only screen and (max-width: 1610px), screen and (max-height: 910) {
  .point__endgame {
    width: 150px;
  }
  .point__board {
    margin-top: 80px !important;
  }
  .point__teamscores {
    margin-top: -50px;
  }
}
@media only screen and (max-width: 1280px), screen and (max-height: 720px) {
  .point__board {
    margin-top: 30px !important;
  }
  .point__teamscores {
    margin-top: 0px;
  }
}
@media only screen and (max-width: 1366), screen and (max-height: 768px) {
  .point__teamscores {
    font-size: 18px !important;
    transform: translateY(-2px) !important;
  }
  .point__controls {
    width: 1000px;
    margin-top: 80px !important;
  }
}
@media only screen and (max-width: 1024px), screen and (max-height: 800px) {
  .point__board {
    margin-top: 45px;
  }
  .point__teamscores {
    font-size: 16px;
    transform: translateY(20px);
  }
  .point__controls {
    width: 1000px;
    margin-top: 130px;
  }
}
