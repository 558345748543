.gameend__bg {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -3;
  background: url("../images/map1.webp");
  height: 100%;
  width: 100%;
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.gameend {
  font-family: "PaytoneOne";
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 50px;
  text-align: center;
  letter-spacing: 0.03em;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.end__text {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -62.4px;
  margin-left: -210px;
}
.end__btn {
  background: url("../images/button.png") no-repeat;
}
