.newgame__bg {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -3;
  background: url('../images/bglogo.webp');
  height: 100%;
  width: 100%;
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.newgame__box {
  background: url('../images/box.webp');
  width: 755px;
  height: 524px;
  padding: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 304px;
}
#hostbtn {
  background-color: #c6905b;
}
.newgame__linkbtn {
  margin-top: 20px;
  width: 686px;
  background-color: #9b5825;
  font-family: 'PaytoneOne';
  font-weight: 400;
  font-style: normal;
  font-size: 22px;
  line-height: 30.71px;
  letter-spacing: 5%;
}
.newgame__linkbtn > button {
  width: 50%;
  height: 60px;
  color: white;
  border: none;
  background-color: #9b5825;
}
#host {
  display: none;
}
.newgame__join,
.newgame__host {
  text-align: center;
}
.newgame__card {
  position: relative;
  margin-top: 18%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
input[type='text'],
input[type='password'] {
  border-color: #9b5825;
  width: 29.188rem;
  height: 3.75rem;
  padding: 15px;
  text-align: center;
  font-size: 20px;
  letter-spacing: 3px;
  background-image: linear-gradient(to right, #d38a31, #c76f21);
  border-radius: 8px;
  border: 3px solid #68360f;
  box-sizing: border-box;
}

.newgame__joinbtn,
.newgame__hostbtn {
  background: url('../images/button.png') no-repeat;
  color: white;
  font-family: 'caesar';
  font-size: 25px;
  width: 400px;
  height: 110px;
  cursor: pointer;
  border: none;
}

@media only screen and (max-width: 1610px), screen and (max-height: 910) {
  body {
    overflow-y: hidden;
  }
  .newgame__box {
    transform: translateY(-40px);
    width: 703px;
    height: 549px;
    background-size: 703px 549px;
  }
  .newgame__linkbtn {
    width: 620px;
  }
}
@media only screen and (max-width: 1400px), screen and (max-height: 800px) {
  .newgame__box {
    margin-top: 270px !important;
    width: 654px;
    height: 500px;
    background-size: 654px 500px;
  }
  .newgame__linkbtn {
    width: 575px;
    transform: translate(-5px, -4px);
  }
}
@media only screen and (max-width: 1360px), screen and (max-height: 768px) {
  .newgame__box {
    margin-top: 210px;
    width: 554px;
    height: 400px;
    background-size: 554px 400px;
  }
  .newgame__linkbtn {
    width: 490px;
    transform: translate(-10px, -15px);
  }
  .newgame__joinbtn,
  .newgame__hostbtn {
    width: 300px;
    height: 80px;
    background-size: 300px, 50px;
  }
  .newgame__join,
  .newgame__host {
    transform: translateY(-40px);
  }
  #newgame__pass {
    width: 25rem;
    height: 3rem;
    padding: 5px 0px;
    font-size: 18px;
  }
}
@media only screen and (max-width: 1024px), screen and (max-height: 720px) {
  .newgame__joinbtn,
  .newgame__hostbtn {
    transform: translateY(-25px);
  }
}

@media only screen and (max-width: 1024px), screen and (max-height: 810px) {
  .newgame__box {
    margin-top: 230px;
  }
}
