#ImageInput {
  font-family: 'PaytoneOne';
  width: 29.1rem;
  height: 3.75rem;
  padding: 5px 50px;
}

@media only screen and (max-width: 1360), screen and (max-height: 682px) {
  #ImageInput {
    width: 25rem;
    height: 3rem;
    padding: 5px 0px;
    font-size: 18px;
  }
}
