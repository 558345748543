.lobby__bg {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -3;
  background: url('../images/bglogo.webp');
  height: 100%;
  width: 100%;
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.lobby__teamtable {
  position: absolute;
  width: 80.5rem;
  top: 35%;
  left: 50%;
  margin-left: -650px !important;
}
.lobby__redteam {
  background: url('../images/join.png') no-repeat;
  cursor: pointer;
  border: none;
  height: 35px;
  width: 100px;
  position: absolute;
  z-index: 3 !important;
  top: 55px;
  transform: translate(465px, 10px);
}
.lobby__blueteam {
  background: url('../images/join.png') no-repeat;
  cursor: pointer;
  border: none;
  height: 35px;
  width: 100px;
  position: absolute;
  z-index: 3 !important;
  top: 55px;
  transform: translate(1120px, 12px);
}
.lobby__startbtn {
  background: url('../images/button.png') no-repeat;
  transform: translateX(435px);
}
.lobby__codediv {
  background: url('../images/copy_code.webp') no-repeat;
  position: absolute;
  z-index: 3;
  display: grid;
  place-items: center;
  font-family: 'caesar';
  height: 60px;
  width: 248px;
  color: white;
  letter-spacing: 3px;
  font-weight: bolder;
  font-size: 25px;
  cursor: pointer;
  transform: translate(32rem, -4rem);
}
.lobby__code {
  letter-spacing: 5px;
  transform: translate(0px, -1px);
}
.lobby__startbtn {
  color: white;
  font-family: 'caesar';
  font-size: 25px;
  width: 400px;
  height: 110px;
  cursor: pointer;
  border: none;
}
.lobby__playermsg {
  color: #603913cc;
  font-family: 'PaytoneOne';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.1em;
  text-align: center;
}
@media only screen and (max-width: 1610px), screen and (max-height: 910) {
  .lobby__teamtable {
    position: absolute;
    width: 62rem;
    top: 35%;
    left: 52%;
    /* margin-left: -400px; */
  }
  .lobby__blueteam {
    transform: translate(850px, -10px);
  }
  .lobby__redteam {
    transform: translate(350px, -10px);
  }
  .lobby__codediv {
    font-size: 16px;
    background-size: 180px;
    transform: translate(31.5rem, -2.5rem);
    margin-top: -10px;
  }
  .lobby__code {
    transform: translate(-35px, -8px);
  }
  .lobby__startbtn {
    background-size: 250px;
    width: 250px;
    height: 70px;
    transform: translateX(470px);
  }
}
@media only screen and (max-width: 1400px), screen and (max-height: 800px) {
  .lobby__teamtable {
    position: absolute;
    /* width: 75rem; */
    top: 35% !important;
    left: 50%;
    margin-left: -31rem !important;
  }
  .lobby__teamcontainer {
    margin-top: 15px;
  }
  .lobby__codediv {
    font-size: 20px;
    background-size: 180px;
    transform: translate(28.5rem, -2.5rem);
  }
  .lobby__code {
    transform: translate(-35px, -8px);
  }
  .lobby__startbtn {
    background-size: 300px;
    width: 300px;
    height: 80px;
    transform: translateX(345px);
  }
}
@media only screen and (max-width: 1100px), screen and (max-height: 800px) {
  .lobby__codediv {
    transform: translate(400px, -40px);
  }
  .lobby__code {
    font-size: 16px;
  }
  .lobby__teamtable {
    top: 35%;
    left: 50%;
    margin-left: -32.5rem;
  }
  .lobby__blueteam {
    transform: translate(860px, -12px);
  }
  .lobby__redteam {
    transform: translate(360px, -12px);
  }
}
