.red__bg {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -3;
  background: url('../images/bglogo.webp');
  height: 100%;
  width: 100%;
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.red__enterhint {
  font-family: 'PaytoneOne';
  position: absolute;
  top: 50%;
  margin-top: -105.8px;
  left: 50%;
  margin-left: -233.5px;
}
.red__enterbtn {
  background: url('../images/button.png') no-repeat;
  color: white;
  font-family: 'caesar';
  font-size: 25px;
  width: 400px;
  height: 110px;
  border: none;
}
.red__timer {
  position: absolute;
  width: 100px;
  top: 129px;
  left: 286px;
}
.red__timer h3 {
  font-family: 'PaytoneOne';
  transform: translate(-73px, -41px);
  color: white;
}
.red__teamranks {
  font-family: 'PaytoneOne';
  background: url('../images/teamrank.svg') no-repeat;
  position: absolute;
  width: 306px;
  height: 60px;
  top: 15%;
  right: 10%;
}
.red__blueranks {
  font-family: 'PaytoneOne';
  background: url('../images/teamrank.svg') no-repeat;
  position: absolute;
  width: 240px;
  background-size: 240px;
  top: 22%;
  right: 11.5%;
}
.red__blueranks h3 {
  font-size: 24px;
  padding: 7px 0 30px 0;
}
.red__title {
  position: absolute;
  color: #9b5825;
  font-family: 'PaytoneOne';
  top: 110px;
  right: 475px;
}
.red__bluescore {
  position: absolute;
  color: #9b5825;
  font-family: 'PaytoneOne';
  top: 210px;
  right: 400px;
}
@media only screen and (max-width: 1280px), screen and (max-height: 720px) {
  .red__timer {
    width: 75px;
    top: 100px;
    left: 150px;
  }
  .red__timer h3 {
    transform: translate(-60px, -30px);
    font-size: 24px;
  }
  .red__title {
    top: 75px;
    right: 175px;
  }
  .red__teamranks {
    background-size: 250px;
    width: 250px;
    height: 60px;
    top: 15%;
    right: 125px;
  }
  .red__teamranks h3 {
    font-size: 18px;
    transform: translateY(-7px);
  }
  .red__bluescore {
    font-size: 20px;
    top: 160px;
    right: 155px;
  }
}
@media only screen and (max-width: 1360), screen and (max-height: 682px) {
  .red__title {
    top: 68px;
    right: 175px;
  }
  .red__bluescore {
    font-size: 20px;
    top: 155px;
    right: 155px;
  }
  .red__teamranks h3 {
    font-size: 18px;
  }
}
