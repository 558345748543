@font-face {
  font-family: "caesar";
  src: url("./css/fonts/CaesarDressing-Regular.ttf");
}
@font-face {
  font-family: "PaytoneOne";
  src: url("./css/fonts/PaytoneOne-Regular.ttf");
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
