.gusser__bg {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -3;
  background: url('../images/bglogo.webp');
  height: 100%;
  width: 100%;
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.gusser__back {
  position: absolute;
  top: 15%;
  left: 20%;
  cursor: pointer;
}
.guesser__title {
  position: absolute;
  color: #9b5825;
  font-family: 'PaytoneOne';
  top: 110px;
  top: 110px;
  right: 475px;
}
.gusser__teamranks {
  background: url('../images/teamrank.svg') no-repeat;
  position: absolute;
  width: 306px;
  height: 60px;
  top: 15%;
  right: 20%;
}
.gusser__teamranks > h3 {
  font-family: 'PaytoneOne';
}
.gusser__hints {
  display: flex;
  justify-content: space-evenly;
  font-family: 'PaytoneOne';
  height: 126px;
  width: 592px;
  position: absolute;
  left: 50%;
  margin-left: -296px;
  top: 50%;
  margin-top: -150px;
  border: 3px solid #68360f;
  border-radius: 8px;
  background: linear-gradient(92.17deg, #d38a31 16.61%, #c76f21 87.7%);
}
.row1,
.row2 {
  width: 592px;
}
.gusser__enterdiv {
  position: absolute;
  font-family: 'PaytoneOne';
  text-align: center;
  left: 50%;
  margin-left: -233.5px;
  top: 50%;
}
.gusser__enterbtn {
  background: url('../images/button.png') no-repeat;
  color: white;
  font-family: 'caesar';
  font-size: 25px;
  width: 400px;
  height: 110px;
  border: none;
}
.gusser__timer {
  position: absolute;
  width: 100px;
  top: 129px;
  left: 286px;
}
.gusser__timer h3 {
  font-family: 'PaytoneOne';
  transform: translate(-73px, -42px);
  color: white;
}
